import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Memberinfo from './components/Memberinfo';
import Contact from './components/Contact';
import BecomeMember from './components/BecomeMember';
import Schemas from './components/Schemas';
import WeatherWebcam from './components/WeatherWebcam';
import ContentEditor from './components/ContentEditor';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import { AuthProvider } from './components/AuthContext';
import './styles/App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <div className="app-container">
          <Navbar />
          <div className="main-container">
            <div className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/memberinfo" element={<Memberinfo />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/becomemember" element={<BecomeMember />} />
                <Route path="/schemas" element={<Schemas />} />
                <Route path="/weatherwebcam" element={<WeatherWebcam />} />
                <Route path="/edit/:contentId" element={<ContentEditor />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
            <Sidebar />
          </div>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
