import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/TideTimes.css';
import highTideIcon from '../assets/high-tide.svg';
import lowTideIcon from '../assets/low-tide.svg';

const TideTimes = () => {
  const [tideData, setTideData] = useState([]);
  const [date, setDate] = useState('');

  useEffect(() => {
    const fetchTideData = async () => {
      const today = new Date().toISOString().split('T')[0];
      const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];

      try {
        const response = await axios.get('https://vannstand.kartverket.no/tideapi.php', {
          params: {
            lat: 62.485700,
            lon: 6.696640,
            fromtime: `${today}T00:00`,
            totime: `${tomorrow}T00:00`,
            datatype: 'tab',
            refcode: 'cd',
            lang: 'nb',
            interval: '60',
            dst: '0',
            tide_request: 'locationdata'
          }
        });
        const xml = new window.DOMParser().parseFromString(response.data, "text/xml");
        const tideElements = xml.getElementsByTagName('waterlevel');
        const tideArray = Array.from(tideElements).map(elem => ({
          time: elem.getAttribute('time'),
          value: elem.getAttribute('value'),
          flag: elem.getAttribute('flag')
        }));

        if (tideArray.length > 0) {
          const firstTideTime = new Date(tideArray[0].time);
          setDate(firstTideTime.toLocaleDateString('no-NO', { year: 'numeric', month: 'long', day: 'numeric' }));
        }

        setTideData(tideArray);
      } catch (error) {
        console.error("Error fetching tide data:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    };

    fetchTideData();
  }, []);

  return (
    <div className="tide-container">
      <h3>Flo og fjøre i dag ({date})</h3>
      {tideData.map((tide, index) => (
        <div key={index} className="tide-item">
          <img 
            src={tide.flag === 'high' ? highTideIcon : lowTideIcon} 
            alt={tide.flag === 'high' ? 'High Tide' : 'Low Tide'} 
            className="tide-icon" 
          />
          <div className="tide-info">
            <span className="tide-time">{new Date(tide.time).toLocaleTimeString('no-NO', { hour: '2-digit', minute: '2-digit' })}</span>
            <span className="tide-value">{tide.value} cm</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TideTimes;
