import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import ContentEditor from './ContentEditor';
import { Button, TextField, Grid, Paper, Typography, Alert } from '@mui/material';
import '../styles/styles.css'; // Sikre at stilen er importert

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const Contact = () => {
  const [content, setContent] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({ name: '', email: '', message: '', type: 'contact' });
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/contact`);
        setContent(response.data);
      } catch (err) {
        console.error('Failed to fetch content', err);
      }
    };
    fetchContent();
  }, []);

  const handleSave = (updatedContent) => {
    if (updatedContent) {
      setContent(updatedContent);
    }
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Vennligst prøv igjen.' });
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, formData);
      setMessageStatus({ type: 'success', message: 'Meldingen din har blitt sendt!' });
      setFormData({ name: '', email: '', message: '', type: 'contact' });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      if (err.response) {
        console.error('Failed to send message', err.response.data);
        setMessageStatus({ type: 'error', message: 'Kunne ikke sende meldingen. Vennligst prøv igjen senere.' });
      } else {
        console.error('Failed to send message', err.message);
        setMessageStatus({ type: 'error', message: 'Kunne ikke sende meldingen. Vennligst prøv igjen senere.' });
      }
    }
  };

  return (
    <div className="main-content">
      {isEditing ? (
        <ContentEditor
          contentId="contact"
          initialTitle={content.title}
          initialContent={content.content}
          onSave={handleSave}
        />
      ) : (
        <>
          <h2>{content.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
          {user && (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Endre
            </Button>
          )}
          <Paper elevation={3} className="paper-container">
            <Typography variant="h5" gutterBottom>
              Send en e-post til oss
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Navn"
                    className="text-field"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="E-post"
                    type="email"
                    className="text-field"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Melding"
                    className="text-field"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    For å unngå spam til oss, må du skrive inn koden under eksakt slik det står:
                  </Typography>
                  <Typography variant="h6" gutterBottom style={{ fontFamily: 'monospace', backgroundColor: '#f0f0f0', padding: '8px', display: 'inline-block' }}>
                    {captcha}
                  </Typography>
                  <TextField
                    label="Eksakt kode som står over"
                    className="text-field"
                    value={captchaInput}
                    onChange={handleCaptchaChange}
                    required
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="button-container"
              >
                Send
              </Button>
            </form>
            {messageStatus.message && (
              <Alert severity={messageStatus.type} style={{ marginTop: '16px' }}>
                {messageStatus.message}
              </Alert>
            )}
          </Paper>
        </>
      )}
    </div>
  );
};

export default Contact;
