import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import ContentEditor from './ContentEditor';
import { Button } from '@mui/material';
import '../styles/Memberinfo.css';


const Memberinfo = () => {
  const [news, setNews] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/memberinfo/news`);
        const sortedNews = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setNews(sortedNews);
      } catch (err) {
        console.error('Failed to fetch news', err);
      }
    };
    fetchNews();
  }, []);

  const handleSave = (updatedContent) => {
    if (updatedContent) {
      const currentDate = new Date().toISOString();
      if (editingIndex !== null) {
        const updatedNews = [...news];
        updatedNews[editingIndex] = { ...updatedContent, created_at: currentDate, author: user.displayName };
        setNews(updatedNews.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
      } else {
        const newNews = { ...updatedContent, created_at: currentDate, author: user.displayName };
        setNews([newNews, ...news]);
      }
    }
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleDelete = async (index) => {
    const newsId = news[index].id;
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/memberinfo/news/${newsId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      setNews(news.filter((_, i) => i !== index));
    } catch (err) {
      console.error('Failed to delete news', err);
    }
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  return (
    <div className="main-content">
      <h2>Info til medlemmer</h2>
      {news.map((item, index) => (
        <div key={index} className="news-item">
          <h3>{item.title}</h3>
          <p className="news-date">
            {item.created_at ? `${parseDate(item.created_at)} - ${item.author}` : 'No Date'}
          </p>
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
          {user && (
            <div className="news-item-actions">
              <Button variant="outlined" color="primary" onClick={() => { setIsEditing(true); setEditingIndex(index); }}>Endre</Button>
              <Button variant="outlined" color="secondary" onClick={() => handleDelete(index)}>Slett innlegg</Button>
            </div>
          )}
        </div>
      ))}
      {isEditing ? (
        <ContentEditor
          contentId={editingIndex !== null ? news[editingIndex].id : "new"}
          initialTitle={editingIndex !== null ? news[editingIndex].title : ''}
          initialContent={editingIndex !== null ? news[editingIndex].content : ''}
          onSave={handleSave}
          isNews={true}
        />
      ) : (
        user && (
          <Button variant="outlined" color="primary" onClick={() => { setIsEditing(true); setEditingIndex(null); }}>Legg til innlegg</Button>
        )
      )}
    </div>
  );
};

export default Memberinfo;
