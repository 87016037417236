import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 > Date.now()) {
          setUser({ token, username: decoded.username, displayName: decoded.displayName });
        } else {
          sessionStorage.removeItem('token');
        }
      } catch (error) {
        console.error('Invalid token:', error);
        sessionStorage.removeItem('token');
      }
    }
  }, []);

  const login = async (username, password) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, { username, password });
      setUser({ token: res.data.token, username: res.data.username, displayName: res.data.displayName });
      sessionStorage.setItem('token', res.data.token);
    } catch (err) {
      console.error('Login failed', err.response ? err.response.data : err.message);
      throw new Error('Login failed');
    }
  };

  const logout = () => {
    setUser(null);
    sessionStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
