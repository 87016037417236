import React, { useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography } from '@mui/material';
import '../styles/Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      setPassword(''); 
      navigate('/');
    } catch (err) {
      setError('Login failed. Please check your credentials and try again.');
      setPassword('');
    }
  };

  return (
    <div className="login-container">
      <h2>Administratorinnlogging</h2>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <TextField
            label="Brukernavn"
            variant="outlined"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
          />
          <TextField
            label="Passord"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" type="submit">
            Logg inn
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </form>
    </div>
  );
};

export default Login;
