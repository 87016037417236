import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`navbar-list ${isOpen ? 'active' : ''}`}>
        <li className="navbar-item">
          <Link to="/" className="navbar-link" onClick={toggleMenu}>Hjem</Link>
        </li>
        <li className="navbar-item">
          <Link to="/memberinfo" className="navbar-link" onClick={toggleMenu}>Medlemsinfo</Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" className="navbar-link" onClick={toggleMenu}>Kontakt oss</Link>
        </li>
        <li className="navbar-item">
          <Link to="/becomemember" className="navbar-link" onClick={toggleMenu}>Bli medlem</Link>
        </li>
        <li className="navbar-item">
          <Link to="/schemas" className="navbar-link" onClick={toggleMenu}>Skjema og dokument</Link>
        </li>
        <li className="navbar-item">
          <Link to="/weatherwebcam" className="navbar-link" onClick={toggleMenu}>Vær og webkamera</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
