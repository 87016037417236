import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import ContentEditor from './ContentEditor';
import { Button } from '@mui/material';
import WeatherAlerts from './WeatherAlerts';  // Import WeatherAlerts

const Home = () => {
  const [content, setContent] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    const fetchContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/home`);
        if (isMounted.current) {
          setContent(response.data);
        }
      } catch (err) {
        if (isMounted.current) {
          console.error('Failed to fetch content', err);
        }
      }
    };
    fetchContent();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSave = (updatedContent) => {
    if (updatedContent && isMounted.current) {
      setContent(updatedContent);
    }
    setIsEditing(false);
  };

  return (
    <div className="main-content">
      <WeatherAlerts />  {/* Add WeatherAlerts component here */}
      {isEditing ? (
        <ContentEditor
          contentId="home"
          initialTitle={content.title}
          initialContent={content.content}
          onSave={handleSave}
        />
      ) : (
        <>
          <h2>{content.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
          {user && (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Endre
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default Home;
