import React, { useState, useEffect, useContext } from 'react';
import { Button, Paper, Typography, Grid, Collapse, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Alert, TextField, Box } from '@mui/material';
import { AuthContext } from './AuthContext';
import BecomeMember from './BecomeMember'; // Import skjemaer her
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import '../styles/Schemas.css'; // Import stilarket

const Schemas = () => {
  const { user } = useContext(AuthContext);
  const [selectedForm, setSelectedForm] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [editingFile, setEditingFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');

  const forms = [
    { code: 'BecomeMember', title: 'Bli medlem' },
    { code: 'ContactForm', title: 'Kontaktskjema' },
    { code: 'ApplicationStorageHall', title: 'Søknad om plass i lagringshall' }
  ];

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
        setDocuments(response.data);
      } catch (err) {
        setError('Feil ved henting av dokumenter');
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const handleToggleForm = (code) => {
    if (selectedForm === code) {
      setSelectedForm(null);
    } else {
      setSelectedForm(code);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('document', file);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/documents`, formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setOpenUploadDialog(false);
      setFile(null);
      // Refresh document list
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
      setDocuments(response.data);
    } catch (err) {
      setError('Feil ved opplasting av fil');
    }
  };

  const handleDelete = async (filename) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/documents/delete/${filename}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      // Refresh document list
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
      setDocuments(response.data);
    } catch (err) {
      setError('Feil ved sletting av fil');
    }
  };

  const handleEdit = (document) => {
    setEditingFile(document);
    setNewFileName(document.name.replace(/\.[^/.]+$/, ""));
  };

  const handleRename = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/documents/rename/${editingFile.name}`, { newName: `${newFileName}${editingFile.name.substr(editingFile.name.lastIndexOf('.'))}` }, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      setEditingFile(null);
      setNewFileName('');
      // Refresh document list
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
      setDocuments(response.data);
    } catch (err) {
      setError('Feil ved endring av navn på fil');
    }
  };

  const renderForm = () => {
    switch (selectedForm) {
      case 'BecomeMember':
        return <BecomeMember />;
      case 'ContactForm':
        return <div>Kontaktskjema (ikke implementert)</div>;
      case 'ApplicationStorageHall':
        return <div>Søknad om plass i lagringshall (ikke implementert)</div>;
      default:
        return null;
    }
  };

  const getIconByFileType = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  return (
    <div className="main-content">
      <h2>Skjema og dokument</h2>
      <Typography variant="h5" gutterBottom style={{ marginTop: '32px' }}>
        Skjema
      </Typography>
      <Grid container spacing={3}>
        {forms.map((form) => (
          <Grid item key={form.code} xs={12} sm={6} md={4}>
            <Paper elevation={3} className="schemas-paper">
              <Typography variant="h6" gutterBottom>
                {form.title}
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleToggleForm(form.code)}
              >
                {selectedForm === form.code ? 'Lukke skjema' : 'Åpne skjema'}
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Collapse in={selectedForm !== null}>
        <div className="schemas-form-container">
          {renderForm()}
        </div>
      </Collapse>

      <Typography variant="h5" gutterBottom style={{ marginTop: '32px' }}>
        Dokumenter
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <List>
          {documents.map((document) => (
            <ListItem key={document.name}>
              <ListItemAvatar>
                <Avatar>
                  {getIconByFileType(document.name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={
                  <a href={`${process.env.REACT_APP_BACKEND_URL}${document.path}`} target="_blank" rel="noopener noreferrer">
                    {document.name.replace(/\.[^/.]+$/, "")}
                  </a>
                } 
              />
              {user && (
                <>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(document)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(document.name)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </ListItem>
          ))}
        </List>
      )}
      {user && (
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" startIcon={<UploadIcon />} onClick={() => setOpenUploadDialog(true)}>
            Last opp dokument
          </Button>
          <Dialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)}>
            <DialogTitle>Last opp dokument</DialogTitle>
            <DialogContent>
              <input type="file" onChange={handleFileChange} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpload} color="primary">Last opp</Button>
              <Button onClick={() => setOpenUploadDialog(false)} color="secondary">Avbryt</Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      {editingFile && (
        <Dialog open={!!editingFile} onClose={() => setEditingFile(null)}>
          <DialogTitle>Endre navn på dokument</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Nytt navn"
              variant="outlined"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRename} color="primary">Lagre</Button>
            <Button onClick={() => setEditingFile(null)} color="secondary">Avbryt</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Schemas;
