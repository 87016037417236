import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { nb } from 'date-fns/locale';
import '../styles/TrendPopup.css';

const typeTitleMap = {
  temperature: 'Temperaturtrend',
  humidity: 'Luftfuktighetstrend',
  barometer: 'Barometertrend',
  rain: 'Regntrend',
  daily_rainfall: 'Nedbørstrend',
  currwind: 'Vindhastighetstrend',
  gustwind: 'Vindkasttrend'
};

const TrendPopup = ({ selectedLocation, selectedType, trendData, onClose, error = '', chartWidth = '100%' }) => {
  const chartCanvas = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const filteredTrendData = trendData.filter((data, index) => {
      const hour = new Date(data.timestamp).getHours();
      return index === 0 || new Date(trendData[index - 1].timestamp).getHours() !== hour;
    });

    if (filteredTrendData.length > 0 && chartCanvas.current) {
      const ctx = chartCanvas.current.getContext('2d');
      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: filteredTrendData.map(data => new Date(data.timestamp)),
          datasets: [{
            label: selectedType,
            data: filteredTrendData.map(data => data.value),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: false,
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                tooltipFormat: 'HH:mm',
                displayFormats: {
                  hour: 'HH:mm'
                }
              },
              adapters: {
                date: {
                  locale: nb
                }
              }
            },
            y: {
              ticks: {
                callback: function(value) {
                  const suffixMap = {
                    temperature: ' °C',
                    humidity: ' %',
                    barometer: ' hPa',
                    rain: ' mm',
                    daily_rainfall: ' mm',
                    currwind: ' m/s',
                    gustwind: ' m/s'
                  };
                  return value + (suffixMap[selectedType] || '');
                }
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(tooltipItem) {
                  const suffixMap = {
                    temperature: ' °C',
                    humidity: ' %',
                    barometer: ' hPa',
                    rain: ' mm',
                    daily_rainfall: ' mm',
                    currwind: ' m/s',
                    gustwind: ' m/s'
                  };
                  return tooltipItem.formattedValue + (suffixMap[selectedType] || '');
                }
              }
            },
            legend: {
              display: false
            }
          }
        }
      });
    }
  }, [trendData, selectedType]);

  const closePopup = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    onClose();
  };

  const title = `${typeTitleMap[selectedType] || selectedType} siste 24t`;

  return (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <h2 className="trend-popup-title"><b>{title}</b></h2>
        <button className="close-btn" onClick={closePopup}>X</button>
        <div className="trend-data">
          {error ? (
            <p>Ingen data tilgjengelig</p>
          ) : (
            <canvas ref={chartCanvas} style={{ width: chartWidth }}></canvas>
          )}
        </div>
      </div>
    </div>
  );
};

TrendPopup.propTypes = {
  selectedLocation: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  trendData: PropTypes.arrayOf(PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })).isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string,
  chartWidth: PropTypes.string
};

export default TrendPopup;
