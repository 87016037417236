// DieselPricesWidget.js

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import '../styles/Sidebar.css';
import TankIcon from '../assets/TankIcon.svg';

const DieselPricesWidget = () => {
  const { user } = useContext(AuthContext);
  const [dieselPrice, setDieselPrice] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [kroner, setKroner] = useState('');
  const [ore, setOre] = useState('');

  useEffect(() => {
    const fetchDieselPrice = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/dieselprice`);
        setDieselPrice(response.data.content);

        // Bruk riktig felt og riktig format for datoen
        const updatedDate = new Date(response.data.created_at);
        setLastUpdated(updatedDate.toLocaleDateString('no-NO'));  // Formatér til norsk datoformat
      } catch (error) {
        console.error("Failed to fetch diesel price", error);
      }
    };

    fetchDieselPrice();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);

    // Fyll inn eksisterende pris i krone- og øre-feltene når redigering starter
    if (dieselPrice) {
      const [currentKroner, currentOre] = dieselPrice.split(',');
      setKroner(currentKroner || '0');
      setOre(currentOre || '00');
    }
  };

  const handleSave = async () => {
    if (!user || !user.token) {
      console.error('User not authenticated');
      return;
    }

    const combinedPrice = `${kroner},${ore.padStart(2, '0')}`;

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/content/dieselprice`,
        { content: combinedPrice },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      setDieselPrice(combinedPrice);
      setLastUpdated(new Date().toLocaleDateString('no-NO'));
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update diesel price", error);
    }
  };

  return (
    <div className="diesel-widget-container">
      <div className="diesel-widget">
        <div className="diesel-icon" style={{ marginBottom: '10px' }}>
          <img src={TankIcon} alt="Tank Icon" width="64" height="64" />
        </div>

        <span className="diesel-widget-price">
          Dieselpris: {dieselPrice ? `${dieselPrice},- pr. liter` : "Laster..."}
        </span>
        {lastUpdated && (
          <div className="diesel-widget-updated">
            Sist endret: {lastUpdated}
          </div>
        )}
        {user && (
          <div>
            {isEditing ? (
              <>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <input
                    type="number"
                    value={kroner}
                    onChange={(e) => setKroner(e.target.value)}
                    placeholder="Kroner"
                    className="diesel-price-input"
                    min="0"
                  />
                  <span>,</span>
                  <input
                    type="number"
                    value={ore}
                    onChange={(e) => setOre(e.target.value)}
                    placeholder="Øre"
                    className="diesel-price-input"
                    min="0"
                    max="99"
                  />
                </div>
                <button onClick={handleSave} className="diesel-save-button">
                  Lagre
                </button>
                <button onClick={() => setIsEditing(false)} className="diesel-cancel-button">
                  Avbryt
                </button>
              </>
            ) : (
              <button onClick={handleEditClick} className="diesel-edit-button">
                Endre pris
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DieselPricesWidget;
