import React, { useEffect, useState, createRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../styles/WeatherAlerts.css';

const WeatherAlerts = () => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      const response = await fetch('https://api.met.no/weatherapi/metalerts/2.0/current.json?lat=62.50488&lon=6.69015');
      if (response.ok) {
        const data = await response.json();
        setAlerts(data.features.map(alert => ({ ...alert, expanded: false, nodeRef: createRef() })));
      }
    };

    fetchAlerts();
  }, []);

  const getSeverityClass = (severity) => {
    switch (severity) {
      case 'Moderate':
        return 'yellow-alert';
      case 'Severe':
        return 'orange-alert';
      case 'Extreme':
        return 'red-alert';
      default:
        return '';
    }
  };

  const getSeverityLabel = (severity) => {
    switch (severity) {
      case 'Moderate':
        return 'Gult farevarsel';
      case 'Severe':
        return 'Oransje farevarsel';
      case 'Extreme':
        return 'Rødt farevarsel';
      default:
        return severity;
    }
  };

  const getIcon = (event) => {
    switch (event) {
      case 'forestFire':
        return 'fas fa-fire';
      case 'flood':
        return 'fas fa-water';
      case 'storm':
        return 'fas fa-wind';
      case 'avalanche':
        return 'fas fa-snowflake';
      case 'rainFlood':
      case 'rain':
        return 'fas fa-cloud-showers-heavy';
      case 'wind':
        return 'fas fa-wind';
      default:
        return 'fas fa-exclamation-triangle';
    }
  };

  const toggleAlert = (index) => {
    setAlerts(alerts.map((alert, i) => {
      if (i === index) {
        return { ...alert, expanded: !alert.expanded };
      }
      return alert;
    }));
  };

  const formatDateTime = (dateTime) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    const formattedDateTime = new Date(dateTime).toLocaleString('no-NO', options).replace(',', ' klokken');
    return formattedDateTime;
  };

  if (alerts.length === 0) {
    return null; // Return null if there are no alerts
  }

  return (
    <div className="alerts-wrapper">
      {alerts.map((alert, index) => (
        <div key={`${alert.id}-${index}`} className={`alert-box ${getSeverityClass(alert.properties.severity)}`} onClick={() => toggleAlert(index)}>
          <h3 className="alert-header">
            <i className={getIcon(alert.properties.event)}></i>
            {alert.properties.eventAwarenessName}
            <span className="toggle-text">{alert.expanded ? 'Vis mindre' : 'Vis mer'}</span>
          </h3>
          <div className="alert-details">
            <b>Grad:</b> {getSeverityLabel(alert.properties.severity)}
          </div>
          <CSSTransition
            in={alert.expanded}
            timeout={300}
            classNames="alert-content"
            unmountOnExit
            nodeRef={alert.nodeRef}
          >
            <div ref={alert.nodeRef} className="alert-content">
              <br /><b>Beskrivelse:</b> {alert.properties.description}
              <div style={{ marginTop: '10px' }}>
                {alert.when && alert.when.interval && (
                  <>
                    <br /><b>Gjelder fra:</b> {formatDateTime(alert.when.interval[0])}
                    <br /><b>Gjelder til:</b> {formatDateTime(alert.when.interval[1])}
                  </>
                )}
              </div>
            </div>
          </CSSTransition>
        </div>
      ))}
    </div>
  );
};

export default WeatherAlerts;
