import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo-link">
          <img src={`${process.env.PUBLIC_URL}/img/ssbflogo.png`} alt="Skodje småbåtforening logo" className="logo" />
        </Link>
        <Link to="/" className="title-link">
          <h1 className="title">Skodje småbåtforening</h1>
        </Link>
      </div>
    </header>
  );
};

export default Header;
