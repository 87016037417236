import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import ContentEditor from './ContentEditor';
import { Button, TextField, Grid, Paper, Typography, Alert } from '@mui/material';

const BecomeMember = () => {
  const [content, setContent] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', address: '', type: 'becomemember' });
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/becomemember`);
        setContent(response.data);
      } catch (err) {
        console.error('Failed to fetch content', err);
      }
    };
    fetchContent();
  }, []);

  const handleSave = (updatedContent) => {
    if (updatedContent) {
      setContent(updatedContent);
    }
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Sending form data:', formData); // Log form data
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, formData);
      setMessageStatus({ type: 'success', message: 'Søknaden din har blitt sendt!' });
      setFormData({ name: '', email: '', phone: '', address: '', type: 'becomemember' });
    } catch (err) {
      if (err.response) {
        console.error('Failed to send message', err.response.data); // Log response data
        setMessageStatus({ type: 'error', message: 'Kunne ikke sende søknaden. Vennligst prøv igjen senere.' });
      } else {
        console.error('Failed to send message', err.message);
        setMessageStatus({ type: 'error', message: 'Kunne ikke sende søknaden. Vennligst prøv igjen senere.' });
      }
    }
  };

  return (
    <div className="main-content">
      {isEditing ? (
        <ContentEditor
          contentId="becomemember"
          initialTitle={content.title}
          initialContent={content.content}
          onSave={handleSave}
        />
      ) : (
        <>
          <h2>{content.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
          {user && (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Endre
            </Button>
          )}
          <Paper 
            elevation={3} 
            style={{ padding: '32px', marginTop: '32px', backgroundColor: '#fff' }}
          >
            <Typography variant="h5" gutterBottom>
              Bli medlem
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Navn"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                    style={{ marginBottom: '16px' }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="E-post"
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                    style={{ marginBottom: '16px' }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="phone"
                    label="Telefon"
                    fullWidth
                    value={formData.phone}
                    onChange={handleInputChange}
                    style={{ marginBottom: '16px' }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="address"
                    label="Adresse"
                    fullWidth
                    value={formData.address}
                    onChange={handleInputChange}
                    style={{ marginBottom: '16px' }}
                    required
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
              >
                Send søknad
              </Button>
            </form>
            {messageStatus.message && (
              <Alert severity={messageStatus.type} style={{ marginTop: '16px' }}>
                {messageStatus.message}
              </Alert>
            )}
          </Paper>
        </>
      )}
    </div>
  );
};

export default BecomeMember;
